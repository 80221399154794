body {
  font-family: Baskerville;
  margin: 0;
  background-color: white;
  min-width: 0;
  min-height: 0;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
