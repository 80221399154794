.calendar {
  font-family: 'Jost-Light';
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: transparent;
  font-family: 'Jost-Light';
  line-height: 1.125em;
}

.react-calendar header {
  font-family: 'Jost-Light';
  color: white;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none !important;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  color: #000814;
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation button:disabled {
  color: gray;
}

.react-calendar__navigation__label {
  font-family: 'Jost-Light';
  color: #000814 !important;
  font-size: 1em
}

.react-calendar__month-view__weekdays {
  font-family: 'Jost-Regular';
  color: #000814;
  text-align: center;
  font-size: 0.9em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  text-decoration: none !important;
  border: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #000814;
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px;
  font-size: 0.9em;
  font-family: 'Jost-Light';
  color: #000814;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  color: #787878;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #000814;
  color: white;
}

.react-calendar__tile--now {
  background: transparent;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: transparent;
}

.react-calendar__tile--hasActive {
  background-color: #000814;
  color: white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: #000814;
  color: white;
}

.react-calendar__tile--active {
  background-color: #000814;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #000814;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #000814;
  color: white;
}
